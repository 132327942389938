﻿function trackLandingPage() {
    (function () {
        var a = String(Math.random()) * 10000000000000;
        new Image().src = 'https://pubads.g.doubleclick.net/activity;dc_iu=/8900/DFPAudiencePixel;ord=' + a + ';dc_seg=6512692194?';
    })();

    (function () {
        var a = String(Math.random()) * 10000000000000;
        new Image().src = 'https://pubads.g.doubleclick.net/activity;xsp=4714578;ord=' + a + '?';
    })();
}


function trackForm() {
    (function () {
        var a = String(Math.random()) * 10000000000000;
        new Image().src = 'https://pubads.g.doubleclick.net/activity;dc_iu=/8900/DFPAudiencePixel;ord=' + a + ';dc_seg=6512652868?';
    })();

    (function () {
        var a = String(Math.random()) * 10000000000000;
        new Image().src = 'https://pubads.g.doubleclick.net/activity;xsp=4714149;ord=' + a + '?';
    })();
}

function trackThankYouPage() {
    (function () {
        var a = String(Math.random()) * 10000000000000;
        new Image().src = 'https://pubads.g.doubleclick.net/activity;dc_iu=/8900/DFPAudiencePixel;ord=' + a + ';dc_seg=6513113363?';
    })();

    (function () {
        var a = String(Math.random()) * 10000000000000;
        new Image().src = 'https://pubads.g.doubleclick.net/activity;xsp=4714152;ord=' + a + '?';
    })();
}