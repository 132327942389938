﻿
function HumanApiConnect(appUrl, entityNumber, publicToken, accessTokenIDSVR) {
    var entityNumber = entityNumber;
    var clientId = "2b1b44a4ff38d6f43d076337f9cbcbf308a71755";

    var publicTokenValue;
    if (publicToken == undefined || publicToken == null) {
        publicTokenValue = "";
    } else {
        publicTokenValue = publicToken;
    }

    abphc.ui.clearBusy();

    var options = {
        clientUserId: encodeURIComponent(entityNumber),
        clientId: clientId,
        publicToken: publicTokenValue,
        finish: function (err, sessionTokenObject) {
            abphc.ui.setBusy();
            formComponent.registerEntity(err, sessionTokenObject);
        },
        close: function () {
            /* (optional) Called when a user closes the popup
                     without connecting any data sources */
            formComponent.callOnClose(false);
        },
        error: function (err) {
            /* (optional) Called if an error occurs when loading
                     the popup. */
        },
    };
    HumanConnect.open(options);
}

function yodleeConnect(JwtToken) {    
    window.fastlink.open(
        {
            fastLinkURL: abphc.setting.values['Yodlee.Node'],                
            jwtToken: "Bearer " + JwtToken,
            params: {
                callback: "https://www.google.com/webhp?igu=1",
            },
            forceIframe: true,
            onSuccess: function (data) {
                yodleeComponent.registerToElevateEnv();
                financialComponent.getAllDataYodlee(data.providerAccountId);
                console.log(data);
            },
            onError: function (data) {
                console.log('Connect Error - ' + JSON.stringify(data));
                yodleeComponent.onError(data);
            },
            onExit: function (data) {
                yodleeComponent.close();
            },
            onEvent: function (data) {
                console.log('Connect Event - ' + JSON.stringify(data));
            }
        },
        "container-fastlink"
    );
}

function yodleeRefresh(JwtToken, providerAccountId){
    yodleeComponent.showModal();
    globalJwtToken = JwtToken;

    window.fastlink.open({
        fastLinkURL: abphc.setting.values['Yodlee.Node'],
        jwtToken: "Bearer " + JwtToken,
        forceIframe: true,
        params: { providerAccountId : providerAccountId,
                flow : 'refresh',
                userExperienceFlow: 'Aggregation'
                 }, 
                 onEvent: function (data) {
                    console.log('Refesh Event - ' + JSON.stringify(data));
                    yodleeComponent.showModal();
                },
                onSuccess: function (data)
                {
                    financialComponent.getAllDataYodlee(providerAccountId);
                },
                onExit: function (data)
                {
                    yodleeComponent.close();
                },
                onError: function (data)
                {
                    console.log('Refesh Error - ' + JSON.stringify(data));
                    yodleeComponent.onError(data);
                }
        }, 'container-fastlink');
    }

function yodleeClose() {
    window.fastlink.close();
    yodleeComponent.checkDeregister();
}

var refreshWindow = false;

var globalProviderAccountIds = null;

var globalJwtToken;

var formComponent;

var abphc;

var yodleeComponent;

var financialComponent;